import axios from "axios";
import { accessTokenApi } from "./helper";

const ekycService = axios.create({
  baseURL: process.env.REACT_APP_EKYC_URL_ENDPOINT,
});

ekycService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["Authorization"] = localStorage.getItem("accessToken");
    tmpConfig.headers["X-Api-Key"] = process.env.REACT_APP_EKYC_X_API_KEY;
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ekycService.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      accessTokenApi();
    }
  }
);

const loginService = axios.create({
  baseURL: process.env.REACT_APP_BASE_LOGIN_ENDPOINT,
});

loginService.interceptors.request.use(
  (config) => {
    const tmpConfig = config;
    tmpConfig.headers["Authorization"] = localStorage.getItem("loginToken");
    tmpConfig.headers["x-api-Key"] = "vQcPvlqXnSaTRkyES8tBG692QDWN01uJ2PfkUj3Q";
    tmpConfig.headers["Content-Type"] = "application/json";
    tmpConfig.headers["Cookie"] =
      "XSRF-TOKEN=2b1d7709-11e3-4f4b-90a5-9addb4450ff9";
    return tmpConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { ekycService, loginService };
