import React from "react";
import TextField from "@mui/material/TextField";

const EdlInputBox = ({
  id,
  label,
  changeValue,
  type,
  maxLength,
  value,
  onClick,
  classes,
  step,
  required,
  variant,
  error,
}) => {
  const textFieldRef = React.useRef(null);
  React.useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    textFieldRef?.current?.addEventListener("wheel", handleWheel);
    return () => {
      textFieldRef?.current?.removeEventListener("wheel", handleWheel);
    };
  }, []);
  return (
    <div className="custome-input">
      <TextField
        autoComplete="nope"
        id={id}
        classes={classes}
        label={
          <>
            {label} {required && <span style={{ color: "red" }}>*</span>}
          </>
        }
        onChange={changeValue}
        type={type}
        inputProps={{ maxLength, inputMode: "numeric", pattern: "[0-9]*" }}
        value={value}
        onClick={onClick}
        // InputLabelProps={{ required }}
        step={step}
        variant={variant}
        error={error}
      />
    </div>
  );
};
export default EdlInputBox;
