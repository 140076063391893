/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { useState } from "react";
// import { Link } from "react-router-dom";

const Dashboard = () => {
  // const [data, setData] = useState([
  //   1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3,
  // ]);

  // const handleResend = (e) => {
  //   e.preventDefault();
  // };

  return (
    <div className="col-md-12 bg-white">
      <h5>Dashboard</h5>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="tablehead">Customer Name</th>
            <th className="tablehead">Mobile Number</th>
            <th className="tablehead">Source</th>
            <th className="tablehead">Lead Id</th>
            <th className="tablehead">Status</th>
            <th className="tablehead">Resend SMS</th>
            <th className="tablehead">Action</th>
            <th className="tablehead">AML</th>
          </tr>
        </thead>

        {/* <tbody>
          {data?.map((item, key) => (
            <tr key={`data_${key}`}>
              <td>Shrikant Chavan</td>
              <td>987456321</td>
              <td>CP</td>
              <td>112233</td>
              <td>Pending</td>
              <td>
                <Link to="" onClick={handleResend}>
                  Resend
                </Link>
              </td>
              <td>
                <Link to="/dashboard/123">view Details</Link>
              </td>
              <td>92%</td>
            </tr>
          ))}
        </tbody> */}
      </table>
    </div>
  );
};
export default Dashboard;
