import React from "react";
import zunoLogo from "../Assets/images/zuno_logo.png";

export const Header = () => {
  return (
    <header>
      <div className="header">
        <div className="ps-5">
          <img className="edl-logo " src={zunoLogo} alt="Zuno Logo" />
        </div>
      </div>
    </header>
  );
};
