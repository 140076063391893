import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: "120vh",
    // paddingTop: "30px",
  },
  stepTitle: {
    "text-align": "left",
    "margin-left": "150px",
    "margin-top": "-75px",
    color: "var(--aqua-9)",
  },
}));

const HorizontalStepper = ({ stepLabels, activeBlock }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="steper">
        <Stepper
          className={classes.button}
          activeStep={activeBlock}
          orientation="horizontal"
          alternativeLabel
        >
          {stepLabels.map((label, index) => (
            <Step key={label}>
              <StepLabel>
                <div className={`${classes.stepTitle} text-start`}>{label}</div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
};

HorizontalStepper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  stepLabels: PropTypes.array,
  activeBlock: PropTypes.number,
  quoteId: PropTypes.string,
  isDisableStepper: PropTypes.string,
};

export default HorizontalStepper;
