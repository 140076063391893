import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const handleClick = () => {
    localStorage.removeItem("leadId");
    localStorage.removeItem("leadInfo");
    localStorage.removeItem("offlineKycPage");
    localStorage.removeItem("page");
  };

  return (
    <nav
      id="sidebarMenu"
      className="collapse d-lg-block sidebar collapse bg-white"
    >
      <div className="position-sticky text-center menubar">
        <div className="zone mx-3 d-flex flex-column gap-4">
          {/* <NavLink to="/dashboard" className="pl-2 menu-text">
            Dashboard
          </NavLink> */}
          <NavLink
            to="/kycSteps"
            className="pl-2 menu-text"
            onClick={handleClick}
          >
            eKYC
          </NavLink>
          <NavLink
            to="/offlineKyc"
            className="pl-2 menu-text"
            onClick={handleClick}
          >
            Offline KYC
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
