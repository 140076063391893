import axios from "axios";
import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
const REACT_APP_ENCRYPT_KEY = "tGz6tGz6qKFHvzvDzOGSTEDKTEHDBfkdHF";

export const accessTokenApi = async (setToken) => {
  await axios
    .post(
      "https://apis.edelweissinsurance.com/oauth2/token",
      {},
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            "Basic N3RmcWxlazhhNmRpNjlncWV2bG5zcG1sb3A6MWY2dTgzbWVlNGF1bjJ2YWFpdjd2bzAydGVtZmg4ZWJnb251ZWQzb3U5czg3N3F0aDZyYw==",
        },
      }
    )
    .then((res) => {
      if (res.data.access_token) {
        localStorage.setItem("accessToken", res.data.access_token);
        setToken(localStorage.getItem("accessToken"));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getLeadInfo = (
  leadId,
  error,
  ekycService,
  setLeadInfo,
  setScreen,
  setIsLoading,
  setToasterDetials,
  isOfflineKyc
) => {
  if (leadId.trim() === "") {
    setToasterDetials(toasterInfo(true, "Please enter lead id", "error"));
  } else if (leadId && error.trim() === "") {
    setIsLoading(true);
    ekycService
      .get(`signzy/get-lead-info?LeadId=${leadId}`)
      .then((res) => {
        const response = res.data;
        setIsLoading(false);
        if (typeof response?.data === "object") {
          setLeadInfo(response?.data);
          localStorage.setItem("leadInfo", JSON.stringify(response?.data));
          localStorage.setItem("leadId", response?.data?.LEAD_ID);
          localStorage.setItem(isOfflineKyc ? "offlineKycPage" : "page", 1);
          setScreen(1);
        } else {
          setToasterDetials(toasterInfo(true, response?.data, "error"));
        }
      })
      .catch((err) => {
        const msg =
          err && err.response && err.response.status === 400
            ? err.response.data.message
            : "Something went wrong!";
        setToasterDetials(toasterInfo(true, msg, "error"));
        setIsLoading(false);
        console.log(err);
      });
  }
};

export const toasterInfo = (isShow, message, status) => {
  const toasterInfo = {};
  toasterInfo.showToaster = isShow;
  toasterInfo.toastMessage = message;
  toasterInfo.toastStatus = status;
  return toasterInfo;
};

export const ridirectUrl = (source) => {
  const baseUrlCP = "https://www.hizuno.com";
  if (source === "2W") {
    return `${baseUrlCP}/two-wheeler-insurance`;
  } else if (source === "4W") {
    return `${baseUrlCP}/four-wheeler-insurance`;
  } else if (source === "HE") {
    return `${baseUrlCP}/health-insurance`;
  } else if (source === "MR") {
    return `${baseUrlCP}/motor-renewal`;
  } else if (source === "HR") {
    return `${baseUrlCP}/health-renewal`;
  }
};

export const decryptData = (payload) => {
  const decryptedString = CryptoAES.decrypt(
    payload,
    REACT_APP_ENCRYPT_KEY
  ).toString(CryptoENC);
  try {
    return JSON.parse(decryptedString);
  } catch {
    return decryptedString;
  }
};

export const encryptData = (payload) => {
  if (typeof payload !== "string") {
    payload = JSON.stringify(payload);
  }
  return CryptoAES.encrypt(payload, REACT_APP_ENCRYPT_KEY).toString();
};
