export const regexConst = {
  panRegex: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
  numberRegex: /^\d+$/,
  alphaNumeric: /^[a-zA-Z0-9]+$/,
};

export const constants = {
  maxFileSize: 26214400,
  allowedExtensionsOnly: ["jpg", "jpeg", "png"],
};
