/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import "./Assets/sidebar.css";
import "egi-ui-component/css/main.css";
import { Helmet } from "react-helmet";
import { Header } from "./Components/Header";
import { Footer } from "./Components/Footer";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginWIthEmail from "./Components/eKYCComponents/LoginWIthEmail";
import KYCSteps from "./Components/eKYCComponents/KYCSteps";
import PrivateComponent from "./Components/PrivateComponent";
import Dashboard from "./Components/eKYCComponents/Dashboard";
import Sidebar from "./Components/Sidebar";
import OfflineKyc from "./Components/eKYCComponents/OfflineKyc";
import { useEffect } from "react";
import { useState } from "react";
import PolicyDetails from "./Components/eKYCComponents/PolicyDetails";

function App() {
  const [isShow, setIsShow] = useState(false);
  const [isRedirected, setIsRedirected] = useState(false);
  const showHideSidebar = localStorage.getItem("showHideSidebar");
  useEffect(() => {
    if (window.location.pathname === "/" || showHideSidebar === "hide")
      setIsShow(false);
    else setIsShow(true);
  }, [window.location.pathname, showHideSidebar, isRedirected]);

  return (
    <div className="App">
      <Helmet>
        <title>eKYC</title>
      </Helmet>
      <Router>
        <Header />
        {isShow && <Sidebar />}
        <div className={`${isShow ? "refundBlock" : "pt-3"}`}>
          <div className={`${isShow ? " refundback" : "fullPageAlignment"}`}>
            <Routes>
              <Route
                path="/"
                element={<LoginWIthEmail setIsRedirected={setIsRedirected} />}
              />
              <Route element={<PrivateComponent />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard/:id" element={<PolicyDetails />} />
                <Route path="/kycSteps" element={<KYCSteps />} />
                <Route path="/offlineKyc" element={<OfflineKyc />} />
              </Route>
              <Route
                path="*"
                element={
                  localStorage.getItem("auth") ? (
                    <KYCSteps />
                  ) : (
                    <LoginWIthEmail />
                  )
                }
              />
            </Routes>
          </div>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
