import { useState } from "react";
import { ekycService } from "../axiosInterceptor";
import { regexConst } from "../constant";
import { getLeadInfo } from "../helper";
import Loader from "../LoaderComponent/Loader";

const LeadIdInput = ({
  leadId,
  setLeadId,
  setLeadInfo,
  setScreen,
  setToasterDetials,
  isOfflineKyc,
}) => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLeadId = (e) => {
    const leadId = e.target.value;
    setLeadId(leadId);
    if (leadId?.trim() === "") {
      setError("Please enter lead id");
    } else if (!regexConst.alphaNumeric.test(leadId)) {
      setError("Please enter valid lead id");
    } else {
      setError("");
    }
  };

  const onLeadIdSubmit = () => {
    //LeadId=123233
    getLeadInfo(
      leadId,
      error,
      ekycService,
      setLeadInfo,
      setScreen,
      setIsLoading,
      setToasterDetials,
      isOfflineKyc
    );
  };

  return (
    <>
      <h3 className="pt-4">
        Please proceed with lead id to complete your eKYC for Policy Issuance
      </h3>
      <div className="panAndAdhaar">
        {isLoading ? (
          <Loader />
        ) : (
          <form>
            <div className="form-group pb-4 pt-4">
              <label htmlFor="pan_number" className="pb-2">
                Please enter lead id for eKYC:
              </label>
              <input
                type="text"
                className="form-control"
                id="pan_number"
                value={leadId}
                onChange={handleLeadId}
              />
              <span className="err">{error}</span>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onLeadIdSubmit}
            >
              Proceed
            </button>
          </form>
        )}
      </div>
    </>
  );
};
export default LeadIdInput;
