import { createTheme } from "@mui/material/styles";
// import * as Colors from "core/constants/CommonColors";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(0, 70, 79)",
    },
    secondary: {
      main: "rgb(255, 118, 113)",
    },
  },

  typography: {
    fontFamily: [
      "Helvetica",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",

      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    //TextBox
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "var(--aqua-9) !important",
          backgroundColor: "var(--aqua-2) !important",
          borderRadius: "50px !important",
        },
        input: {
          borderColor: "var(--aqua-9)!important",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        InputProps: { disableUnderline: true },
        variant: "standard",
      },
      styleOverrides: {
        root: {
          color: "var(--aqua-9) !important",
          backgroundColor: "var(--aqua-2) !important",
          borderRadius: "50px !important",
          width: "300px !important",
        },
        "&.fieldset": { color: "var(--aqua-9) !important" },
        "&.input:invalid + fieldset": {
          border: "none",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        "&.fieldset": { color: "var(--aqua-9) !important" },
        "&.select:invalid + fieldset": {
          border: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "var(--aqua-9) !important",
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          color: "var(--aqua-9) !important",
          backgroundColor: "var(--aqua-2) !important",
          borderRadius: "25px !important",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: "var(--aqua-2) !important",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 3px 14px var(--aqua-1) !important",
          borderRadius: "25px !important",
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        paper: {
          backgroundColor: "white !important",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: "25px !important",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          paddingRight: "10px",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "var(--coral-4)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "25px !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});
export default theme;
