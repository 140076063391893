/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { debounce } from "debounce";
import Loader from "../LoaderComponent/Loader";
import { ekycService } from "../axiosInterceptor";
import SendIcon from "@mui/icons-material/Send";
import { constants, regexConst } from "../constant";
import { Button, Link } from "@mui/material";
import { toasterInfo } from "../helper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import downloadicon from "../../Assets/images/downloadicon.svg";
import EdlInputBox from "../CommonComponents/EdlInputBox";
// import { useSnackbar } from "notistack";

const AdhaarVerification = ({
  setScreen,
  screen,
  panDetails,
  setToasterDetials,
  leadId,
  handleClosetoast,
  isHideSidebar,
  leadInfo,
}) => {
  const [isPoll, setIsPoll] = useState(false);
  const [reqId, setReqId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDisplayUpload, setIsDisplayUpload] = useState(false);
  const [radioValue, setRadioValue] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [ckycNumber, setCkycNumber] = useState("");
  const [error, setError] = useState(undefined);
  const [isCkycNeeded, setIsCkycNeeded] = useState(false);
  // const [ckycDetails, setCkycDetails] = useState({});
  const ckycNeededSourses = ["PP", "CP", "SAP", "FIRE"];
  const [documentList, setDocumentList] = useState([]);
  const [isDocUploaded, setIsDocUploaded] = useState(false);

  useEffect(() => {
    if (!isHideSidebar) {
      setIsLoading(true);
      ekycService
        .get(`signzy/e-kyc-document-download?LeadId=${leadId}`)
        .then((res) => {
          const response = res.data;
          setIsLoading(false);
          if (response?.statusCode === 200) {
            setDocumentList(response?.data);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          const msg =
            err.response.status === 400
              ? err.response.data.message
              : "Something went wrong!";
          setToasterDetials(toasterInfo(true, msg, "error"));
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (ckycNeededSourses.includes(leadInfo?.SOURCE)) {
      setIsCkycNeeded(true);
    } else {
      setIsCkycNeeded(false);
    }
  }, [leadInfo]);

  const pollSeconds = 3 * 1000;
  const call = async (debouncedCallFun) => {
    if (reqId !== "") {
      const adhaarReq = {
        requestId: reqId,
        LeadId: leadId,
      };
      ekycService
        .post(`signzy/adhaar-details`, adhaarReq)
        .then((res) => {
          setIsPoll(false);
          if (res.data.statusCode === 200) {
            setIsLoading(false);
            setIsDisplayUpload(false);
            setToasterDetials(
              toasterInfo(true, "Aadhaar verified successfully!", "success")
            );
            setScreen(3);
            localStorage.setItem("page", 3);
          }
        })
        .catch((err) => {
          if (radioValue === "aadhaar") {
            debouncedCallFun(debouncedCallFun);
          } else {
            setIsPoll(false);
          }
        });
    }
  };
  const debouncedCall = debounce(call, pollSeconds);
  useEffect(() => {
    if (isPoll && screen === 2) {
      debouncedCall(debouncedCall);
    }
  }, [isPoll]);

  const onLinkClick = () => {
    if (radioValue === "aadhaar" || !isCkycNeeded) {
      setIsLoading(true);
      handleClosetoast();
      ekycService
        .get("signzy/create-url")
        .then((res) => {
          if (res.data.data) {
            setIsPoll(true);
            setReqId(res.data.data.requestId);
            window.open(
              res.data.data.url,
              "Adhaar Verification",
              "width=600,height=500,left=400,top=100"
            );
            if (isHideSidebar) {
              setTimeout(() => {
                setIsDisplayUpload(true);
                setIsPoll(false);
                setIsLoading(false);
              }, 1000 * 60 * 5);
            }
          }
        })
        .catch((err) => {
          setIsLoading(false);
          const msg =
            err.response.status === 400
              ? err.response.data.message
              : "Something went wrong!";
          setToasterDetials(toasterInfo(true, msg, "error"));
          console.log(err);
        });
    } else {
      if (error && !error.mobileNumber && !error?.ckycNumber) {
        const req = {
          LeadId: leadId,
          ckycNumber: ckycNumber,
          MobileNo: mobileNumber,
        };
        setIsLoading(true);
        handleClosetoast();
        ekycService
          .post("signzy/c-kyc-details", req)
          .then((res) => {
            setIsLoading(false);
            if (res.data.statusCode === 200) {
              setScreen(3);
              localStorage.setItem("page", 3);
              setToasterDetials(
                toasterInfo(true, "CKYC completed successfully!", "success")
              );
            }
          })
          .catch((err) => {
            setIsLoading(false);
            const msg =
              err.response?.status === 400
                ? err && err.response
                  ? err.response.message
                  : "Something went wrong!"
                : "Something went wrong!";
            setToasterDetials(toasterInfo(true, msg, "error"));
            console.log(err);
          });
      }
    }
  };

  const onFileChange = (e) => {
    let isValid = true;
    let errorMsg = "";
    if (e.target.files.length > 2) {
      errorMsg = "You can upload only 2 files";
      isValid = false;
    }
    if (isValid) {
      for (let i = 0; i < e.target.files.length; i += 1) {
        for (let file in selectedFiles) {
          if (e.target.files[i].name === selectedFiles[file]?.name) {
            errorMsg = "File Already Uploaded ";
            isValid = false;
            break;
          }
        }
        if (e.target.files[i].size > constants.maxFileSize) {
          errorMsg = "File size should be less than 25MB";
          isValid = false;
          break;
        }
        if (e.target.files[i].name.split(".").length > 2) {
          errorMsg = `File with multiple format is not allowed`;
          isValid = false;
          break;
        }
        if (
          !constants.allowedExtensionsOnly.includes(
            e.target.files[i].name.split(".").at(-1).toLowerCase()
          )
        ) {
          errorMsg = `File type ${e.target.files[i].type} is not allowed`;
          isValid = false;
          break;
        }
      }
    }
    if (isValid) {
      onFileUpload(e.target.files);
    } else {
      setToasterDetials(toasterInfo(true, errorMsg, "error"));
    }
  };

  const onFileUpload = (selectedFilesArr) => {
    const itemList = [];
    Object.keys(selectedFilesArr).map((key, ind) =>
      itemList.push(selectedFilesArr[key])
    );

    const formData = new FormData();
    formData.append("LeadId", leadId);
    for (let i = 0; i < selectedFilesArr?.length; i += 1) {
      formData.append("documentType", "AadharCard");
      formData.append("file", selectedFilesArr[i], selectedFilesArr[i]?.name);
    }

    // axios.post("api/uploadfile", formData);
    setIsLoading(true);
    ekycService
      .post("signzy/e-kyc-document-upload", formData)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setSelectedFiles([...selectedFiles, ...itemList]);
          setToasterDetials(
            toasterInfo(true, "Documents uploaded successfully!", "success")
          );
          setIsDocUploaded(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        const msg =
          err.response.status === 400
            ? err.response.data.message
            : "Something went wrong!";
        setToasterDetials(toasterInfo(true, msg, "error"));
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "aadhaar") {
      setRadioValue(value);
    } else if (value === "ckyc") {
      setRadioValue(value);
    }
  };

  const handleInputChange = (e) => {
    const id = e.target.id;
    const val = e.target.value;
    const errorObj = { ...error };
    if (id === "mobileNumber") {
      setMobileNumber(val);
      if (val?.trim().length < 10) {
        errorObj[id] = "Please enter valid mobile number";
      } else if (val && regexConst.numberRegex.test(val)) {
        delete errorObj[id];
      } else {
        errorObj[id] = "Please enter valid mobile number";
      }
    }
    if (id === "ckycNumber") {
      setCkycNumber(val);
      if (val && regexConst.numberRegex.test(val)) {
        delete errorObj[id];
      } else {
        errorObj[id] = "Please enter valid CKYC number";
      }
    }
    setError(errorObj);
  };

  return (
    <>
      {isLoading ? (
        <div className="panAndAdhaar">
          <Loader />
        </div>
      ) : (
        <>
          <div className="d-flex flex-column pt-2 darkAqua">
            <span>Name: {panDetails?.name}</span>
            <span> PAN number: {panDetails?.number}</span>
            <span>
              Status:{" "}
              {panDetails?.panStatus === "VALID" ? "Verified" : "Not Verified"}
            </span>
            {!isCkycNeeded && (
              <span className="text-info pt-4">
                (Please proceed for aadhaar verification)
              </span>
            )}
            {isDisplayUpload ? (
              <>
                <div className="pt-2">
                  <span className="text-info">
                    (Please upload front & back photo of aadhaar for aadhaar
                    verification)
                  </span>
                </div>
                <div className="pt-2 pb-3">
                  <span className="text-info">
                    (Document should not be greater than 25MB & format should be
                    .jpg,.jpeg,.png)
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          {isHideSidebar ? (
            isCkycNeeded &&
            !isDisplayUpload && (
              <>
                <div className={isHideSidebar ? "radioFullPage" : "radio"}>
                  <FormControl className="">
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={radioValue}
                    >
                      {/* <div className="d-flex flex-column"> */}
                      <FormControlLabel
                        control={<Radio onClick={handleChange} />}
                        value="aadhaar"
                        label="Aadhaar verification"
                      />
                      <FormControlLabel
                        control={<Radio onClick={handleChange} />}
                        value="ckyc"
                        label="CKYC verification"
                      />
                      {/* </div> */}
                    </RadioGroup>
                  </FormControl>
                </div>
                {radioValue === "ckyc" && (
                  <div className={isHideSidebar ? "inputsFullpage" : "inputs"}>
                    <div>
                      {/* <input
                        className="form-control mb-1"
                        type="text"
                        id="mobileNumber"
                        placeholder="Enter mobile number"
                        maxLength={10}
                        onChange={handleInputChange}
                        value={mobileNumber}
                      /> */}
                      <EdlInputBox
                        id="mobileNumber"
                        variant="outlined"
                        label="Enter mobile number"
                        value={mobileNumber}
                        changeValue={handleInputChange}
                        maxLength={10}
                        error={error?.mobileNumber}
                      />
                      {error?.mobileNumber ? (
                        <span className="err">{error?.mobileNumber}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div>
                      {/* <input
                        className="form-control mb-1"
                        type="text"
                        id="ckycNumber"
                        placeholder="Enter CKYC number"
                        onChange={handleInputChange}
                        value={ckycNumber}
                        /> */}
                      <EdlInputBox
                        id="ckycNumber"
                        variant="outlined"
                        label="Enter CKYC number"
                        value={ckycNumber}
                        changeValue={handleInputChange}
                        maxLength={10}
                        error={error?.ckycNumber}
                      />
                      {error?.ckycNumber ? (
                        <span className="err">{error?.ckycNumber}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
              </>
            )
          ) : (
            <>
              {!isHideSidebar && (
                <div className="row pt-4 pb-4">
                  {documentList?.map((item, key) => (
                    <div className="col-4 pt-4" key={`doc_${key}`}>
                      <div className="d-flex flex-column">
                        <img
                          src={item.base64}
                          //   width="95px"
                          alt={item?.DocumentName}
                        />
                        <Button
                          component={Link}
                          href={`data:image/jpeg;base64,${item?.DocumentName}`}
                          download={item?.DocumentName}
                        >
                          <img src={downloadicon} />
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {!isDisplayUpload && (
            <button
              type="button"
              className={`btn btn-switch ${isCkycNeeded ? "m-3" : "mt-4"}`}
              onClick={onLinkClick}
            >
              Proceed
            </button>
          )}

          {isHideSidebar && isDisplayUpload && (
            <div className="pt-4 pb-4">
              <Button variant="contained" component="label" color="primary">
                Upload file
                <input type="file" hidden multiple onChange={onFileChange} />
                <SendIcon sx={{ paddingLeft: "7px" }} />
              </Button>
            </div>
          )}
          {isHideSidebar &&
            isDisplayUpload &&
            selectedFiles &&
            selectedFiles.map((file, i) => (
              <div className="" key={`${file}_${i}`}>
                <span>{file.name}</span>
              </div>
            ))}
          {isDocUploaded ? (
            <div className="mt-2">
              <span className="text-info blink_me">
                We will verify your documents and complete your eKYC soon.
              </span>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
export default AdhaarVerification;
