/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Loader from "../LoaderComponent/Loader";
import { ekycService } from "../axiosInterceptor";
import { useEffect } from "react";
import { getLeadInfo, toasterInfo } from "../helper";
import { regexConst } from "../constant";
import EdlInputBox from "../CommonComponents/EdlInputBox";

const PanVerification = ({
  setScreen,
  setPanDetails,
  setToasterDetials,
  leadId,
  setLeadInfo,
  token,
  isHideSidebar,
}) => {
  const [panNumber, setPanNumber] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lead, setLead] = useState(leadId);

  useEffect(() => {
    setLead(leadId ? leadId : localStorage.getItem("leadId"));
  }, [leadId]);

  useEffect(() => {
    if (isHideSidebar && token) {
      getLeadInfo(
        lead,
        "",
        ekycService,
        setLeadInfo,
        setScreen,
        setIsLoading,
        setToasterDetials
      );
    }
  }, [token, isHideSidebar]);

  const handlePanNumber = (e) => {
    const panNumber = e.target.value?.toUpperCase();
    setPanNumber(panNumber);
    if (regexConst.panRegex.test(e.target.value)) {
      setError("");
    } else {
      setError("Please enter valid pan number");
    }
  };

  const onSubmitPan = () => {
    if (panNumber?.trim() !== "" && error.trim() === "") {
      setIsLoading(true);
      ekycService
        .post("signzy/pan-details", { number: panNumber, LeadId: `${lead}` })
        .then((res) => {
          const response = res.data;
          if (response?.statusCode === 200) {
            setToasterDetials(
              toasterInfo(true, "PAN verified successfully!", "success")
            );
            setScreen(2);
            localStorage.setItem("page", 2);
            setIsLoading(false);
            setPanDetails(res.data.data);
            localStorage.setItem("panDetails", JSON.stringify(res.data.data));
          }
        })
        .catch((err) => {
          console.log(err);
          const msg =
            err.response?.status === 400
              ? err.response?.data?.message
              : "Something went wrong!";
          setToasterDetials(toasterInfo(true, msg, "error"));
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="panAndAdhaar">
      {isLoading ? (
        <Loader />
      ) : (
        <form>
          <div className="form-group pb-2">
            <EdlInputBox
              key="panNumber"
              id="panNumber"
              variant="outlined"
              label="Enter pan number"
              value={panNumber}
              placeholder="Enter pan number"
              changeValue={handlePanNumber}
              required
              error={error}
            />
            <span className="err">{error}</span>
          </div>
          <button
            type="button"
            className="btn m-3 btn-switch"
            onClick={onSubmitPan}
          >
            Proceed
          </button>
        </form>
      )}
    </div>
  );
};
export default PanVerification;
