import React, { useState } from "react";
import Loader from "../LoaderComponent/Loader";
import { ekycService } from "../axiosInterceptor";
import { ridirectUrl, toasterInfo } from "../helper";

const AmlCheck = ({ setScreen, screen, setToasterDetials, leadInfo }) => {
  const [isAMLNegative, setIsAMLNegative] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const cpPortals = ["2W", "4W", "HE", "MR", "HR"];

  const onProceed = async () => {
    setIsLoading(true);
    const req = {
      fullName: `${leadInfo?.FIRST_NAME + leadInfo?.LAST_NAME}`,
      dateOfBirthOrIncorporation: leadInfo?.DOB,
      zipcode: leadInfo?.ZIPCODE,
      LeadId: leadInfo?.LEAD_ID,
    };
    await ekycService
      .post("signzy/negative-check", req)
      .then(async (res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setToasterDetials(
            toasterInfo(true, "AML check successfully!", "success")
          );
          setScreen(4);
          localStorage.setItem("page", 4);
          setIsAMLNegative(res.data.isNegative);
          const redirectUrl = ridirectUrl(leadInfo?.BP_ID);
          const req = {
            url: `${redirectUrl}?LeadId=${leadInfo?.LEAD_ID}&KYC_Status=${
              res.data.isNegative ? 1 : 0
            }`,
          };
          if (cpPortals?.includes(leadInfo?.BP_ID)) {
            await ekycService
              .post("signzy/tiny-url", req)
              .then((res) => {
                if (res.data.statusCode === 200) {
                  window.location.href = res.data.url;
                }
              })
              .catch((err) => {
                setIsLoading(false);
                setToasterDetials(
                  toasterInfo(true, "Something went wrong!", "error")
                );
                console.log(err);
              });
          }
          localStorage.clear();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setToasterDetials(toasterInfo(true, "Something went wrong!", "error"));
        console.log(err);
      });
  };
  return (
    <div className="panAndAdhaar">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="m-4">
          {screen === 4 ? (
            <h2 className={isAMLNegative ? "darkAqua" : "err"}>
              {isAMLNegative
                ? "Thank you for completing eKYC"
                : "Sorry your eKYC is not completed"}
            </h2>
          ) : (
            <button
              className="btn btn-switch-completion"
              type="button"
              onClick={onProceed}
            >
              Procced for eKYC completion
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default AmlCheck;
