/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HorizontalStepper from "../HorizontalStepper";
import AmlCheck from "./AmlCheck";
import LeadIdInput from "./LeadIdInput";
import AdhaarVerification from "./AdhaarVerification";
import PanVerification from "./PanVerification";
import ToastComponent from "../ToastComponent";
import { accessTokenApi } from "../helper";

const KYCSteps = () => {
  const storedLeadId = localStorage.getItem("leadId");
  const page = JSON.parse(localStorage.getItem("page"));
  const storageLeadInfo = JSON.parse(localStorage.getItem("leadInfo"));
  const panInfo = JSON.parse(localStorage.getItem("panDetails"));
  const [screen, setScreen] = useState(page ? page : 0);
  const [panDetails, setPanDetails] = useState(panInfo ? panInfo : {});
  const [leadInfo, setLeadInfo] = useState(
    storageLeadInfo ? storageLeadInfo : {}
  );
  const [leadId, setLeadId] = useState(storedLeadId ? `${storedLeadId}` : "");
  const [toasterDetails, setToasterDetials] = useState({
    showToaster: false,
    toastMessage: "",
    toastStatus: "success",
  });
  const [token, setToken] = useState("");

  const stepLabels = ["Step 1", "Step 2", "Step 3"];
  const isHideSidebar =
    localStorage.getItem("showHideSidebar") === "hide" ? true : false;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    accessTokenApi(setToken);
  }, []);

  const handleClosetoast = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToasterDetials({
      showToaster: false,
      toastMessage: "",
      toastStatus: "",
    });
  };

  return (
    <>
      <ToastComponent
        open={toasterDetails?.showToaster}
        handleClose={handleClosetoast}
        message={toasterDetails?.toastMessage}
        isSuccess={toasterDetails?.toastStatus}
      />
      {screen !== 0 && (
        <HorizontalStepper stepLabels={stepLabels} activeBlock={screen - 1} />
      )}
      {screen > 0 && screen < 4 && (
        <h3 className="darkAqua">
          Hi {storageLeadInfo?.FIRST_NAME}, Please complete your eKYC for policy
          issuance.
        </h3>
      )}
      {screen === 0 ? (
        <LeadIdInput
          leadId={leadId}
          setLeadId={setLeadId}
          setLeadInfo={setLeadInfo}
          setScreen={setScreen}
          setToasterDetials={setToasterDetials}
          isOfflineKyc={false}
        />
      ) : screen === 1 ? (
        <PanVerification
          setScreen={setScreen}
          setPanDetails={setPanDetails}
          setToasterDetials={setToasterDetials}
          leadId={leadId}
          setLeadInfo={setLeadInfo}
          token={token}
          isHideSidebar={isHideSidebar}
        />
      ) : screen === 2 ? (
        <AdhaarVerification
          setScreen={setScreen}
          screen={screen}
          panDetails={panDetails}
          setToasterDetials={setToasterDetials}
          leadId={leadId}
          handleClosetoast={handleClosetoast}
          isHideSidebar={isHideSidebar}
          leadInfo={leadInfo}
        />
      ) : (
        <AmlCheck
          setScreen={setScreen}
          screen={screen}
          setToasterDetials={setToasterDetials}
          leadInfo={leadInfo}
        />
      )}
    </>
  );
};
export default KYCSteps;
