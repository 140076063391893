/* eslint-disable jsx-a11y/alt-text */
import { Button, Link } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import downloadicon from "../../Assets/images/downloadicon.svg";
import { ekycService } from "../axiosInterceptor";
import { toasterInfo } from "../helper";
import Loader from "../LoaderComponent/Loader";
import ToastComponent from "../ToastComponent";

const PolicyDetails = () => {
  const [documentList, setDocumentList] = useState([
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [toasterDetails, setToasterDetials] = useState({
    showToaster: false,
    toastMessage: "",
    toastStatus: "success",
  });

  useEffect(() => {
    setIsLoading(true);
    ekycService
      .get(`signzy/e-kyc-document-download?LeadId=112233`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 200) {
          setDocumentList(res.data.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        const msg =
          err.response.status === 400
            ? err.response.data.message
            : "Something went wrong!";
        setToasterDetials(toasterInfo(true, msg, "error"));
        console.log(err);
      });
  }, []);

  const handleClosetoast = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToasterDetials({
      showToaster: false,
      toastMessage: "",
      toastStatus: "",
    });
  };

  return (
    <>
      {isLoading ? (
        <div className="panAndAdhaar">
          <Loader />
        </div>
      ) : (
        <>
          <ToastComponent
            open={toasterDetails?.showToaster}
            handleClose={handleClosetoast}
            message={toasterDetails?.toastMessage}
            isSuccess={toasterDetails?.toastStatus}
          />
          <h3>PolicyDetails</h3>
          <div className="container alignText">
            <div className="row pt-3">
              <div className="col">
                <span>
                  <strong>Name : Nikhil Angre</strong>
                </span>
              </div>
              <div className="col">
                <span>
                  <strong>Mobile Number : 9096789583</strong>
                </span>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col">
                <span>
                  <strong>Source : CP</strong>
                </span>
              </div>
              <div className="col">
                <span>
                  <strong>Lead Id : 112233</strong>
                </span>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col">
                <span>
                  <strong>Status : Pending</strong>
                </span>
              </div>
              <div className="col">
                <span>
                  <strong>Email Id : nikhil.angre@edelweiss.com</strong>
                </span>
              </div>
            </div>
            <div className="row pt-4">
              <div className="col-6">
                <span>
                  <strong>BP Id : 121</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="row pt-4 pb-4 alignText">
            <strong>Documents:</strong>

            {documentList?.map((item, key) => (
              <>
                <div className="col-4 pt-4" key={`doc_${key}`}>
                  <div className="d-flex flex-column">
                    <img
                      src={item.base64}
                      //   width="95px"
                      alt={item?.DocumentName}
                    />
                    <Button
                      component={Link}
                      href={`data:image/jpeg;base64,${item?.DocumentName}`}
                      download={item?.DocumentName}
                    >
                      <img src={downloadicon} />
                    </Button>
                  </div>
                </div>
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default PolicyDetails;
