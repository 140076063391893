/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import { useNavigate } from "react-router-dom";
import ToastComponent from "../ToastComponent";
import Loader from "../LoaderComponent/Loader";
import { loginService } from "../axiosInterceptor";
import { toasterInfo } from "../helper";
import EdlInputBox from "../CommonComponents/EdlInputBox";

const LoginWIthEmail = ({ setIsRedirected }) => {
  const [emailId, setEmailId] = useState("");
  const [error, setError] = useState("");
  const [toasterDetails, setToasterDetials] = useState({
    showToaster: false,
    toastMessage: "",
    toastStatus: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  // const pathParams = useParams();

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (window.location.search?.includes("leadId")) {
      const leadId = window.location.search.split("=")[1];
      localStorage.setItem("auth", true);
      localStorage.setItem("page", 1);
      localStorage.setItem("leadId", leadId);
      localStorage.setItem("showHideSidebar", "hide");
      navigate("/kycSteps");
    } else {
      if (auth) {
        navigate("/kycSteps");
      } else {
        if (!window.location.search) {
          axios
            .post(
              "https://apis.hizuno.com/oauth2/token",
              {},
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization:
                    "Basic N2ppZXRtc2NrNXY4bzA4YmRkNTZxYmo0cTA6MTRjMTBjMDg0cWwzdjJpcG80ZWFxNHVkcWJ1dnFkajVqa241cnZjcTNmY3M2amlhZWdrOQ==",
                },
              }
            )
            .then((res) => {
              if (res.data.access_token) {
                localStorage.setItem("loginToken", res.data.access_token);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  }, []);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailId(email);
    if (isEmail(e.target.value)) {
      setError("");
    } else {
      setError("Please enter valid email id");
    }
  };

  const onLogin = () => {
    if (emailId.trim() !== "" && error.trim() === "") {
      const loginReq = {
        redirectUrl: "https://ekyc.edelweisscustomeruat.com",
        clientId: "6253ttooknk4k2qb7nfcf0ui3q",
        loginVia: "Edelweiss",
        email: emailId,
      };
      setIsLoading(true);
      loginService
        .post("auth/login", loginReq)
        .then((res) => {
          window.location.href = res.data.data.url;
        })
        .catch((err) => {
          setIsLoading(false);
          setEmailId("");
          console.log(err);
          setToasterDetials(
            toasterInfo(true, err.response.data.data.message, "error")
          );
        });
    }
  };

  useEffect(() => {
    const redirectReq = {
      redirectUrl: "https://ekyc.edelweisscustomeruat.com",
      clientId: "6253ttooknk4k2qb7nfcf0ui3q",
      code: window.location.search?.split("=")[1],
      clientSecret: "3m9ds9uvuu12mqenn13fgtsd5agpdg8oisbr8t76ft7s4183c5",
    };
    if (window.location.search) {
      setIsLoading(true);
      loginService
        .post("auth/token", redirectReq)
        .then((res) => {
          setIsLoading(false);
          localStorage.setItem("auth", true);
          navigate("/kycSteps");
          setIsRedirected(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          setToasterDetials(
            toasterInfo(true, err.response.data.data.message, "error")
          );
        });
    }
  }, [window.location.search]);

  const handleClosetoast = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToasterDetials({
      showToaster: false,
      toastMessage: "",
      toastStatus: "",
    });
    // setShowToaster(false);
  };

  return (
    <>
      <ToastComponent
        open={toasterDetails?.showToaster}
        handleClose={handleClosetoast}
        message={toasterDetails?.toastMessage}
        isSuccess={toasterDetails?.toastStatus}
      />
      {isLoading ? (
        <div className="panAndAdhaar center">
          <Loader />
        </div>
      ) : (
        <form className="panAndAdhaar center">
          <div className="form-group pb-2">
            <EdlInputBox
              key="emailId"
              id="emailId"
              variant="outlined"
              label="Enter email id"
              value={emailId}
              placeholder="Enter email id"
              changeValue={handleEmailChange}
              required
              error={error}
            />
            <span className="err">{error}</span>
          </div>
          <button
            type="button"
            className="btn m-3 btn-switch"
            onClick={onLogin}
          >
            Proceed
          </button>
        </form>
      )}
    </>
  );
};
export default LoginWIthEmail;
